import * as React from "react"
import { Link } from "gatsby"
import * as styles from "../less/styles.scss"
import { StaticImage } from "gatsby-plugin-image"
import "./home/home.scss"
import "./tours/tours.scss"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "./home/components/hero-main"
import FeaturedTours from "./tours/components/featured-tours"
import About from "./home/components/about"
import Services from "./home/components/services"
import Reviews from "./home/components/reviews"


const IndexPage = () => (

 <Layout>
 <Seo title="Home" />
    <Hero/>
    <FeaturedTours/>
    <About/>
    <Services/>
<Reviews/> 
   </Layout>
)

export default IndexPage
